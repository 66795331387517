import * as SentryVue from '@sentry/vue'
import Vue from 'vue'
import Router from '@/App/Router';

global.Sentry = class {
    static init(dsn, environment, user) {
        SentryVue.init({
            Vue,
            dsn,
            //tunnel: Router.generate('error-reporting-tunnel'),
            environment,
            ignoreErrors: ['TypeError: NetworkError when attempting to fetch resource.'],
        });
        SentryVue.setUser({username: user});
    }
};